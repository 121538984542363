<template>
  <div
    style="display: flex; flex-direction: column; height: calc(100vh - 180px)"
  >
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">业务类型</label>
        <el-select
          size="small"
          clearable
          v-model="searchCollectTyp"
          placeholder="全部"
          style="width: 180px"
        >
          <el-option
            v-for="item in collectionType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集时间</label>
        <el-date-picker
          clearable
          v-model="searchCreateDate"
          style="width: 180px"
          type="date"
          placeholder="采集时间"
          autocomplete="off"
          size="small"
        >
        </el-date-picker>
        <label class="fn-14" style="padding-left: 10px">-</label>
        <el-date-picker
          clearable
          v-model="searchCreateDateEnd"
          type="date"
          placeholder="选择采集时间"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
      </div>

      <div class="condition-item">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          v-if="searchButton"
        >
          搜索
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
          size="small"
          v-if="addButton"
        >
          新增采集员
        </el-button>
        <el-button
          type="success"
          @click="handleDownload"
          size="small"
          :loading="DownloadLoading"
          v-if="downloadButton"
        >
          批量下载二维码
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="collectionList"
        v-loading="collectionLoading"
        stripe
        border
        bcollection
        height="100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ collectionLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>

        <el-table-column prop="name" label="姓名" width="150" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span></template
          >
        </el-table-column>

        <el-table-column
          prop="mobile"
          label="手机号"
          width="260"
          align="center"
        >
        <template slot-scope="scope">
            <span>{{ scope.row.mobile }}</span></template
          >
        </el-table-column>

        <el-table-column prop="count" label="精拍" width="200" align="center">
        </el-table-column>

        <el-table-column prop="count" label="快修" width="200" align="center">
        </el-table-column>
        <el-table-column
          prop="count"
          label="现场成片"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="count" label="加急" width="200" align="center">
        </el-table-column>

        <el-table-column prop="batchName" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style=""
              @click="handleSwitch(scope.row)"
              :disabled="scope.row.status == 1"
              v-if="switchStratButton"
            >
              启用
            </el-button>
            <el-button
              type="text"
              style=""
              @click="handleSwitch(scope.row)"
              :disabled="scope.row.status == 0"
              v-if="switchStopButton"
            >
              禁用
            </el-button>

            <el-button type="text" 
            v-if="codeButton"
            style="" @click="handleCode(scope.row.id)">
              下载二维码
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 新增采集员 -->
    <div>
      <el-dialog
        title="新增采集员"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          ref="collectionForm"
          :model="collectionForm"
          label-width="100px"
          :rules="collectionRules"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="姓名" prop="name">
                <el-input
                  style="width: 240px"
                  type="text"
                  v-model="collectionForm.name"
                  placeholder="请输入采集员姓名"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="手机号" prop="mobile">
                <el-input
                  style="width: 240px"
                  type="text"
                  v-model="collectionForm.mobile"
                  placeholder="请输入手机号"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="handleCancel" style="margin-right: 20px"
            >取 消
          </el-button>
          <el-button
            type="primary"
            @click="handleConfirm"
            :loading="confirmLoading"
            >保 存
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryCollectors,
  addCollector,
  updateCollectors,
  qrDownload,
} from "@/api/increment";
import { getSchoolFiller } from "@/api/school";
import { formatPh,formatName } from "@/utils/validate";

import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { queryPageButton } from "@/api/permission";

export default {
  name: "incrementCollection",
  data() {
    var res = /^\d+$|^\d+[.]?\d+$/;
    var tells = /^[1]([3-9])[0-9]{9}$/;
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入采集员姓名"));
      } else {
        callback();
      }
    };

    const validateMobile = (rule, value, callback) => {
      if (!value) {
        //callback(new Error("电话不能为空"));
        callback();
      } else {
        if (tells.test(value) == false) {
          callback(new Error("手机号格式不正确"));
        } else {
          callback();
        }
      }
    };
    return {
      // 列表
      collectionList: [],
      schools: [],
      allSchools: [],
      collectionType: [
        {
          label: "精拍",
          value: 1,
        },
        {
          label: "快修",
          value: 2,
        },
        {
          label: "现场出片",
          value: 3,
        },
        {
          label: "加急",
          value: 4,
        },
      ],
      searchCollectTyp: null,
      searchCreateDate: null,
      searchCreateDateEnd: null,
      collectionLoading: false,
      searchSchoolId: null,
      loading: false,

      currentPage: 1,
      pageSize: 20,
      total: 0,
      // 新增
      collectionForm: {},
      dialogVisible: false,
      confirmLoading: false,
      collectionRules: {
        name: [{ required: true, trigger: "blur", validator: validateName }],
        mobile: [
          {
            required: true,
            trigger: "blur",
            validator: validateMobile,
          },
        ],
      },
      // 下载
      DownloadLoading: false,
      oneLoading: null,

      //按钮权限
      searchButton:false,
      addButton:false,
      downloadButton:false,
      switchStratButton:false,
      switchStopButton:false,
      codeButton:false


    };
  },
  created() {
    this.query();
    this.querySchools();
    this.queryButton();

  },
  methods: {
    queryButton() {
      const data = 128;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "searchButton") {
            this.searchButton = true;
          }  if (button.code === "addButton") {
            this.addButton = true;
          }  if (button.code === "downloadButton") {
            this.downloadButton = true;
          }  if (button.code === "switchStratButton") {
            this.switchStratButton = true;
          }  if (button.code === "switchStopButton") {
            this.switchStopButton = true;
          }  if (button.code === "codeButton") {
            this.searchBucodeButtontton = true;
          }
          
        }
      });
    },
    query() {
      const data = {
        title: this.searchCollectTyp,
        stime: this.searchCreateDate,
        etime: this.searchCreateDateEnd,
      };
      this.collectionLoading = true;
      queryCollectors(data, this.currentPage, this.pageSize).then((resp) => {
        this.collectionList = resp.data.content;
        this.total = resp.data.totalElements;
        this.collectionLoading = false;
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },

    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    handleCode(id) {
      const url = "/api/csias/sys/collectors/qrDownload/" + id;
      this.exportExcel(url, "one");
    },
    exportExcel(url, mod) {
      if (mod == "more") {
        this.DownloadLoading = true;
      } else {
        this.oneLoading = this.$loading({
          lock: true,
          text: "下载中....",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".rar";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载二维码成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.DownloadLoading = false;
          this.oneLoading.close();
        },
        (err) => {
          this.DownloadLoading = false;
          this.oneLoading.close();
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    handleDownload() {
      let json = JSON.parse(JSON.stringify(this.collectionList));
      let ids = [];
      json.map((item, index) => {
        ids.push(item.id);
      });
      ids = ids.join(",");
      const url = "/api/csias/sys/collectors/qrDownload/" + ids;
      this.exportExcel(url, "more");
    },
    // 状态启用
    handleSwitch(data) {
      let postData = {};
      if (data.status == 0) {
        postData.status = 1;
      } else if (data.status == 1) {
        postData.status = 0;
      }
      postData.id = data.id;
      updateCollectors(postData).then((resp) => {
        if (resp.code == 0) {
          this.$notify.success({
            title: "温馨提示",
            message: resp.message,
          });
        } else {
          this.$notify.error({
            title: "错误",
            message: resp.message,
          });
        }
        this.query();
      });
    },
    // 新增采集员
    handleAdd() {
      this.collectionForm = {};
      this.dialogVisible = true;
    },
    handleCancel() {
      this.collectionForm = {};
      this.dialogVisible = false;
    },
    // 保存
    handleConfirm() {
      let postData = this.collectionForm;
      this.$refs.collectionForm.validate((valid) => {
        if (valid) {
          addCollector(postData).then((resp) => {
            this.confirmLoading = true;

            if (resp.code == 0) {
              this.$notify.success({
                title: "温馨提示",
                message: resp.message,
              });
            } else {
              this.$notify.error({
                title: "错误",
                message: resp.message,
              });
            }
            this.query();
            this.confirmLoading = false;
            this.dialogVisible = false;
          });
        }
      });
    },

    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
     // 姓名、手机号脱敏
     execDecrypt(str,mod){
      if (!str) {
          return str;
        } else if (mod == "ph") {
          return formatPh(str);
        } else if (mod == "name") {
          return formatName(str);
        } 
    }
  },
};
</script>

<style></style>
